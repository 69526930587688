import { observable, computed, action } from "mobx";

const LOCAL_STORAGE_KEY = "checkListData";

class TraceItem {
  @observable
  store = null;
  @observable
  frs = undefined;
  @observable
  urs = undefined;
  @observable
  tp = undefined;
  @observable
  test = undefined;
  @observable
  testResult =  undefined;
  @observable
  isFullyMet =  undefined;
  @observable
  isNeedMitigation = undefined;
  @observable
  whereVeriffiedValue = "";
  @observable
  comment = "";

  constructor(store) {
    this.store = store;
  }

  @computed
  get id() {
    const frsUid = this.frs ? `${this.frs.uid}-${this.frs.version}` : "unknown";
    const ursUid = this.urs ? `${this.urs.uid}-${this.urs.version}` : "unknown";

    return `${frsUid}|${ursUid}`;
  }

  @action
  setIsNeedMitigation(value) {
    this.isNeedMitigation =  value;
  }

  @action
  setIsFullyMet(value) {
    this.isFullyMet = value;
  }

  @action
  setTestResult(value) {
    this.testResult = value;
  }

  @action
  setComment(value) {
    this.comment = value;
  }

  @action
  setWhereVeriffied(value) {
    this.whereVeriffiedValue = value;
  }

  @action
  update({ frs, urs, test, tp }) {
    this.frs = frs || this.frs;
    this.urs = urs || this.urs;
    this.tp = tp || this.tp;
    this.test = test || this.test; 
  }

  @computed
  get whereVeriffied() {
    return this.whereVeriffiedValue || this.ursAttrValue;
  }

  @computed
  get data() {
    return {
      testResult:       this.testResult,
      comment:          this.comment,
      isFullyMet:       this.isFullyMet,
      isNeedMitigation: this.isNeedMitigation,
      whereVeriffied:   this.whereVeriffied 
    };
  } 

  @computed
  get attrName() {
    return this.attr && this.attr.name;
  }

  @computed
  get ursAttrValue() {
    if (
      !this.frs 
      || !this.frs.kindsRepresentation 
      || !this.frs.kindsRepresentation.kinds 
      || !this.frs.kindsRepresentation.kinds.get(this.store.kindId)
    ) {
      return null;
    }

    const val = this.frs.kindsRepresentation.kinds.get(this.store.kindId).allValues[this.attr.id];
    return val && val.length ? val : null;
  }

  @computed
  get attr() {
    return this.store.attr;
  }

  @computed
  get indexNumber() {
    return this.urs && this.store.ursIndexMap.get(this.urs.id);
  }

  @computed
  get tpIndexNumber() {
    return this.tp && this.store.tpIndexMap.get(this.tp.id);
  }

  @computed
  get num() {
    return this.urs && this.urs.number;
  }

  @computed
  get isTP() {
    return this.store.isTP;
  }

  @computed
  get isURS() {
    return this.store.isURS;
  }

  @computed
  get isComplete() {
    if (this.isURS) {
      return !!this.urs;
    }
    if (this.isTP) {
      return !!this.tp && !!this.frs;
    }
    return !!this.whereVeriffied && !!this.frs && !!this.urs && !!this.test;
  }

  saveToLocalStorage() {
    const checkListData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
    checkListData[this.id] = this.data;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(checkListData));
  }

  @action
  checkFromLocalStorage() {
    const checkListData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
    const data = checkListData[this.id];
    if (data) {
      this.testResult = data.testResult;
      this.comment = data.comment;
      this.isFullyMet = data.isFullyMet;
      this.isNeedMitigation = data.isNeedMitigation;
      this.whereVeriffiedValue = data.whereVeriffied || "";
    }
  }
}

export default TraceItem;
